import { Container, Text } from 'theme-ui';
import SpinnerBar from '~/components/Spinner/loader';
import { navigate } from 'gatsby';

import { useState, useEffect } from 'react';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import getQueryParams from '~/utils/get-query-params';
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy';

const FinalizeLogin = ({ location }) => {
  const { redirect_path } = getQueryParams(location.search);
  const [displayMessage, setDisplayMessage] = useState(false);
  const redirectTo = redirect_path ?? '';
  const { handleCallback, isLoggedIn } = useAuth();

  useEffect(() => {
    handleCallback()
      .then(() => navigate(redirectTo.length > 0 ? redirectTo : '/account/'))
      .catch(() => navigate('/'));
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayMessage(true);
    }, 8000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <Layout>
      <Metadata />
      <Container sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
        {!isLoggedIn && <SpinnerBar />}
        {displayMessage && (
          <Text sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            Looks like the page is taking too long to load. Try refreshing the page.
          </Text>
        )}
      </Container>
    </Layout>
  );
};

export default FinalizeLogin;
