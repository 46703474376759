const getQueryParams = (query: string): { [key: string]: string | string[] } => {
  let params = {} as { [key: string]: string | string[] };
  new URLSearchParams(query).forEach((value, key) => {
    let decodedKey = decodeURIComponent(key);
    let decodedValue = decodeURIComponent(value);
    // This key is part of an array
    if (decodedKey.endsWith('[]')) {
      decodedKey = decodedKey.replace('[]', '');
      params[decodedKey] || (params[decodedKey] = []);
      // @ts-ignore
      params[decodedKey].push(decodedValue);
      // Just a regular parameter
    } else {
      params[decodedKey] = decodedValue;
    }
  });

  return params;
};

export default getQueryParams;
